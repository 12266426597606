<template>
  <div>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Logging In"
    />
    <div v-else-if="isAuthenticated">
      Thank you for logging in.
    </div>
    <div v-else>
      <v-alert
        v-if="loginError"
        type="error"
      >
        {{ loginError }}
      </v-alert>
      <v-card>
        <v-card-title>
          Login
        </v-card-title>
        <v-form
          ref="form"
          class="login"
          @submit.prevent="login"
        >
          <v-card-text>
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              required
              type="text"
              label="Username"
            />
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              required
              type="password"
              label="Password"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              type="submit"
            >
              Login
            </v-btn>
            <v-btn :to="{name: 'Register', query: { username: username }}">
              Register
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              small
              text
              :to="{name: 'PasswordReset'}"
            >
              Forgot Password?
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <section
        v-if="$online"
        class="discover"
      >
        <div class="video-wrapper">
          <iframe
            src="https://www.youtube.com/embed/SdObc8AyzIo?playlist=SdObc8AyzIo&amp;loop=1&amp;mute=1&amp;showinfo=0&amp;controls=0"
            allowfullscreen=""
            frameborder="0"
          />
        </div>
      </section>

      <section class="discover">
        <img
          class="quest-banner"
          alt="Quest"
          src="@/assets/images/discovery/banner.jpeg"
        >
      </section>

      <section class="discover">
        <div class="quest-features">
          <div class="quest-feature">
            <img
              src="@/assets/images/discovery/epic-adventures.jpeg"
              alt="Epic Adventures"
            >
            <h1>Epic Adventures</h1>
            <p>Solve the clues to discover obscure destinations and embark on exciting explorations of the hidden world around you.</p>
          </div>
          <div class="quest-feature">
            <img
              src="@/assets/images/discovery/skill-challenges.jpeg"
              alt="Skill Challenges"
            >
            <h1>Skill Challenges</h1>
            <p>Put your skills to the test and develop your capabilities through challenging skill-based objectives and CORE challenges.</p>
          </div>
          <div class="quest-feature">
            <img
              src="@/assets/images/discovery/real-community.jpeg"
              alt="Real Community"
            >
            <h1>Real Community</h1>
            <p>Join a growing community of like-minded adventurers dedicated to becoming more capable and living more adventurous lives.</p>
          </div>
        </div>
      </section>

      <section class="discover">
        <div class="step embrace">
          <div class="box right">
            <h1>Embrace the Challenge</h1>
            <ul>
              <li>Find Your Route</li>
              <li>Enjoy The Path</li>
              <li>Reach The Destination</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="discover">
        <GlobalStats />
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import GlobalStats from '@/components/GlobalStats.vue'

export default {
  components: {
    Loading,
    GlobalStats,
  },
  data() {
    return {
      username : null,
      password : null,
      usernameRules: [
        v => !!v || 'Field is required',
      ],
      passwordRules: [
        v => !!v || 'Field is required',
      ],
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      loading: state => state.auth.loading,
      loginError: state => state.auth.loginError,
    })
  },
  created: function () {
    this.next = '/'
    if (this.$route.params && this.$route.params.next) {
      this.next = this.$route.params.next
    }
    this.$store.dispatch('stats/getAll')
  },
  methods: {
    login: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      const credentials = {
        username: this.username.toLowerCase(),
        password: this.password,
      }
      this.$store.dispatch('auth/login', {credentials: credentials, redirect: this.next})
    }
  },
}
</script>

<style lang="scss">
@import 'src/assets/discovery.scss';
</style>
